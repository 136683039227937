import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Arrow from "../images/arrow.inline.svg";
import Layout from "../components/layout";
import Seo from "../components/seo";

const ThankYouPage = () => (
  <Layout>
    <Seo title="Thank You" />
    <article className="article flow">
      <div className="hero-image-wrapper">
        <StaticImage
          className="hero-image"
          src="../images/main-home.jpg"
          width={637}
          height={637}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="a hand full of dirt"
        />
      </div>
      <div className="headline">
        <h1>Thank You</h1>
      </div>
      <div className="article-wrapper flow">
        <p>
          Thank you for your interest in the Agoro Carbon Alliance. A representative will follow up with you soon.
        </p>
        <p>
          Are you a farmer located in the United States and ready to get started? Answer a few eligibility questions and we'll get the ball rolling. 
        </p>
        <a href="/us-growers-eligibility-survey" className="btn btn--secondary">Get Started
          <Arrow
            width={22}
            height={20}
            aria-hidden="true"
            focusable="false"
          />
        </a>
      </div>
    </article>
  </Layout>
);

export default ThankYouPage;
